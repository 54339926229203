import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route} from 'react-router-dom';

import React from 'react';
import './App.css'
import Home from "./pages/home/home";
import JobListing from "./pages/jobs/joblisting";
import JobDetail from "./pages/jobdetail/jobdetail";
import SmoothScroll from './components/SmoothScroll';


function App() {
  return (
      <Router>
        <SmoothScroll>
      <Routes>
          <Route exact path='/'  element={<Home />} />
          <Route path='/jobs' element={<JobListing/>} />
          <Route path='/jobdetail/:id' element={<JobDetail/>} />
          
      </Routes>
      </SmoothScroll>
      </Router>
  );
  }

export default App;
