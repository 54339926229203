import {Link } from "react-router-dom";
export const Openings = (props) => {
  return (
    <div id='features' className='text-center'>
      <div className='container'>
        <div className='col-md-10 col-md-offset-1 section-title padding '>
          <h2 id='openings'>Openings</h2>
        </div>
        <div className='row'>
          {props.data
            ? props.data.map((d, i) => (
              <Link key={`${d.title}-${i}`}
              to={ `/jobdetail/${i}` } 
              //to={{ pathname: `/jobdetail/${i}` }}
              //to="/jobdetail" state={{i}} 
              
            >
              {/* <a href='/jobdetail' > */}
                <div key={`${d.title}-${i}`} className='col-xs-12 col-md-3'>

                  
                  <i className={d.icon}></i>
                  
                  <h3 >{d.title}</h3>
                  
                  
                  <p style={{whiteSpace: 'pre-wrap'}}>{d.text}</p>
                  
                  
                </div>
                </Link>
              ))
            : 'Loading...'}
        </div>
      </div>
    </div>
  )
}

