import { useState, useEffect } from "react";
import { Navigation } from "./components/navigation";


import { Details } from "./components/details";

import { Contact } from "./components/contact";
import JsonData from "../../data/jobs.json";
import SmoothScroll from "smooth-scroll";


export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
});

const JobDetail = () => {
  const [landingPageData, setLandingPageData] = useState({});
  useEffect(() => {
    setLandingPageData(JsonData);
  }, []);

  return (
    <div>
      <Navigation />
      <Details data={landingPageData.Openings} />
      {/* <About data={landingPageData.About} />
      <Services data={landingPageData.Services} />
      <Gallery data={landingPageData.Gallery}/>
      <Testimonials data={landingPageData.Testimonials} />
      <Team data={landingPageData.Team} />*/}
      <Contact data={landingPageData.Contact} /> 
    </div>
  );
};

export default JobDetail;

