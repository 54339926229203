import { useLocation } from "react-router-dom";
import { useParams } from "react-router-dom";


export const Details = (props) => {
  
  
  //const location = useLocation();
  //const id = location.state.i;
  const id = useParams().id;
  
  return (
    <div id="about">
      <div id="details" className="container">
        <div className="row">
          <div className="col-xs-12 col-md-6">
            {" "}
            <img src="/img/about.jpg" className="img-responsive" alt="" />{" "}
          </div>
          <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{props.data ? props.data[id].title : "loading..."}</h2>
              <p>{props.data ? props.data[id].freetext : "loading..."}</p>
            
              <h3 >Job Description</h3>
              <h4>Your Mission</h4>
              <div className="list-style">
                <div className="col-lg-12 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data[id].mission.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
                {/* <div className="col-lg-6 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div> */}
              </div>
              <h4>Requirements</h4>
              <div className="list-style">
                <div className="col-lg-12 col-sm-6 col-xs-12">
                  <ul>
                    {props.data
                      ? props.data[id].responsibilitiy.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : "loading"}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
